import React, { useState, useRef } from "react";
import {
  Form,
  Message,
  Modal,
  Loader,
  Dimmer,
  Divider,
} from "semantic-ui-react";
import EmailEditor from "react-email-editor";
import { capitalize } from "lodash";
import EmailTemplateService from "../../../services/EmailTemplate";
import CONFIG from "../../../Config.js";
import { checkIsAuthorized } from "../../../components/helpers";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import ExportService from "services/Export";

import "../../../styles/forms.scss";
import "./EmailTemplateForm.scoped.scss";

const exportOptions = [
  {
    name: "HTML",
    icon: "code",
    type: "text/html",
    ext: "html",
  },
  {
    name: "PDF",
    icon: "file pdf outline",
    type: "application/pdf",
    ext: "pdf",
  },
  {
    name: "Image",
    icon: "image outline",
    type: "image/png",
    ext: "png",
  },
  {
    name: "ZIP",
    icon: "attach",
    type: "application/zip",
    ext: "zip",
  },
];

const EmailTemplateForm = props => {
  const [name, setName] = useState(props.name);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [design, setDesign] = useState(props.info.design);
  const [viewJSON, setViewJSON] = useState(false);
  const [buttonsEnabled, setButtonsEnabled] = useState(false);
  const [buttonsLoading, setButtonsLoading] = useState({});

  const emailEditorRef = useRef(null);

  const prevDesign = props.info.design;
  const isRawHTML = !!props.info.is_raw_html;
  const options = {
    ...props.options,
    projectId: CONFIG.UNLAYER_PROJECT_ID,
    features: { audit: true },
    fonts: {
      showDefaultFonts: true,
      customFonts: [
        { label: "MS Mincho", value: "'MS Mincho','ＭＳ 明朝'" },
        { label: "MS Gothic", value: "'MS Gothic', 'ＭＳ ゴシック'" },
        { label: "MS PGothic", value: "'MS PGothic', 'ＭＳ Ｐゴシック'" },
      ],
    },
  };
  const canEditEmailTemplates = checkIsAuthorized(
    [
      ACL_RELATIONSHIPS.emailTemplates.create,
      ACL_RELATIONSHIPS.emailTemplates.edit,
    ],
    { partialMatch: true }
  );

  const loadDesign = () => {
    if (props.info.design !== undefined) {
      emailEditorRef.current.editor.loadDesign(props.info.design);
    }
  };

  const handleLoad = () => {
    if (emailEditorRef.current !== undefined) {
      loadDesign();
      setButtonsEnabled(true);
    } else {
      setTimeout(() => handleLoad(), 100);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setSuccess(false);
    setError(false);
    setErrorMessage("");

    emailEditorRef.current.editor.exportHtml(async data => {
      const { design, html } = data;
      try {
        setIsLoading(true);
        const updatedTemplate = await EmailTemplateService.editEmailTemplate({
          id: props.id,
          html,
          info: { ...props.info, design: design, is_raw_html: false },
        });
        setSuccess(true);
        setDesign(design);
        setIsLoading(false);
        await props.fetchTemplate(props.id);
        props.changeTab("HTML", updatedTemplate);
        props.showSuccessMessage();
      } catch (error) {
        setError(true);
        setErrorMessage(error.message);
      }
    });
  };

  const handleJSONChange = event => {
    const { name, value } = event.target;
    if (name === "design") setDesign(JSON.parse(value));
  };

  const handleJSONImport = async e => {
    emailEditorRef.current.editor.loadDesign(design);
    JSONToggle();
    props.showSuccessMessage();
  };

  const JSONToggle = () => setViewJSON(!viewJSON);

  const handleJSONUndo = e => {
    setDesign(prevDesign);
    JSONToggle();
  };

  const handleExport = opt => {
    const unlayer = emailEditorRef.current?.editor;
    setButtonsLoading({ ...buttonsLoading, [opt.name]: true });
    unlayer[`export${capitalize(opt.name)}`](async data => {
      const url = data[opt.name === "HTML" ? "html" : "url"];
      await ExportService.downloadFile(url, {
        name: `${name}.${opt.ext}`,
        mimetype: opt.type,
      });
      setButtonsLoading({ ...buttonsLoading, [opt.name]: false });
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      error={error}
      success={success}
      className="email-template-form"
    >
      <div className="title">
        <h2>{name}</h2>
        <Form.Group inline>
          {exportOptions.map(opt => {
            const loading = !!buttonsLoading[opt.name];
            return (
              <Form.Button
                type="button"
                size="tiny"
                icon={opt.icon}
                onClick={() => handleExport(opt)}
                content={`Export ${opt.name}`}
                disabled={!buttonsEnabled || loading}
                loading={loading}
              />
            );
          })}
        </Form.Group>
      </div>
      <Divider />
      <div style={isRawHTML ? { display: "none" } : {}}>
        <EmailEditor
          minHeight={"60vh"}
          ref={emailEditorRef}
          options={options}
          onLoad={handleLoad}
        />
      </div>
      <Message error header="Action Forbidden" content={errorMessage} />
      <Message success header="Email Template Updated" />
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row-reverse",
        }}
      >
        {!!canEditEmailTemplates && (
          <>
            {isLoading ? (
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            ) : (
              <>
                {!isRawHTML && (
                  <Form.Button size="tiny" primary type="submit">
                    SUBMIT
                  </Form.Button>
                )}
                <JSONImportModal
                  handleToggle={JSONToggle}
                  show={viewJSON}
                  design={design}
                  prevDesign={prevDesign}
                  handleJsonImport={handleJSONImport}
                  handleChange={handleJSONChange}
                  handleCancel={handleJSONUndo}
                />
              </>
            )}
          </>
        )}
      </div>
    </Form>
  );
};

const JSONImportModal = ({
  handleToggle,
  design,
  show,
  handleJsonImport,
  handleChange,
  handleCancel,
}) => {
  return (
    <Modal
      size="small"
      trigger={
        <Form.Button type="button" primary size="tiny" onClick={handleToggle}>
          Edit JSON
        </Form.Button>
      }
      open={show}
      onClose={handleToggle}
      closeIcon
    >
      <Modal.Header>Edit JSON</Modal.Header>
      <Modal.Content>
        <section>
          <Form>
            <Form.TextArea
              name="design"
              value={JSON.stringify(design)}
              onChange={handleChange}
              style={{ minWidth: "100%" }}
              rows={30}
            />
            <div style={{ marginTop: "10px", display: "flex" }}>
              <Form.Button type="button" size="tiny" onClick={handleJsonImport}>
                Apply
              </Form.Button>
              <Form.Button type="button" size="tiny" onClick={handleCancel}>
                Cancel
              </Form.Button>
            </div>
          </Form>
        </section>
      </Modal.Content>
    </Modal>
  );
};

export default EmailTemplateForm;
